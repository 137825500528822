import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import pattern from '../images/sp-pattern-blue.svg';
import Location from '../components/Location';

const Contact = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicContact.data.meta_title}
      meta_description={data.prismicContact.data.meta_description}
    />
    <Helmet>
      <script src="https://www.google.com/recaptcha/api.js" />
    </Helmet>

    <div
      className="hero hero-large z-0 relative bg-blue"
      style={{ backgroundImage: `url(${pattern})`, backgroundSize: '22%' }}
    >
      <div className="hero-body relative z-10">
        <div className="container flex">
          <div className="w-full md:w-1/2 flex self-end text-white ">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicContact.data.hero_text.html,
              }}
            />
          </div>
        </div>
      </div>
    </div>

    <section className="p-0 h-full text-white flex flex-wrap relative z-10">
      <div className="w-full md:w-1/2 bg-grey-light">
        <div className="py-16 container md:px-12 max-w-md">
          <form
            id="sp-contact"
            action="https://api.formbucket.com/f/buk_KJVBGtyTYA8QWklcd8hvhbT4"
            method="post"
            target="_blank"
          >
            <input type="text" name="fullname" placeholder="Full Name" required />
            <input type="text" name="phone" placeholder="Phone Number" />
            <input type="text" name="email" placeholder="Email Address" required />

            <textarea type="text" name="message" placeholder="Message" rows="8" cols="50" required />

            <input type="text" name="honeypot" className="honeypot" />
            <div className="g-recaptcha mb-2" data-sitekey="6Lcb6IUUAAAAAFmvzqNDt0q9Qb8NTpg7CHAyh2EY" />
            <button
              className="border border-2 border-black rounded-full px-6 py-4 no-underline text-black hover:bg-black hover:text-white"
              type="submit"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
      <div className="w-full md:w-1/2 bg-orange-dark">
        <div className="py-16 container md:px-12 max-w-md">
          <h4>Get in touch</h4>
          <p>{data.prismicGlobal.data.phone_number.text}</p>
          <p className="mb-8">
            <a
              href={`mailto: ${data.prismicGlobal.data.email_address.text} `}
              className="no-underline text-white hover:text-orange-darkest"
            >
              {data.prismicGlobal.data.email_address.text}
            </a>
          </p>

          <h4>Address</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicGlobal.data.address.html,
            }}
          />
        </div>
      </div>
    </section>

    <Location />
  </Layout>
);

export default Contact;

export const query = graphql`
  query contactPage {
    prismicContact {
      uid
      id
      data {
        meta_title
        meta_description
        title {
          text
        }
        content {
          html
        }
        hero_text {
          html
        }
      }
    }
    prismicGlobal {
      id
      data {
        address {
          html
        }
        email_address {
          text
        }
        phone_number {
          text
        }
      }
    }
  }
`;
